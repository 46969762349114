 <div [ngStyle]="{'display': isVisible ? '' : 'none'}"> 
  <app-menu-default-card
    [isSidebarCollapsed]="isSidebarCollapsed"
    [ngClass]="isDrawingClass()"
  >
    <div class="h-12 mr-4 items-center" *ngIf="!advancedSearch">
      <app-search-bar 
        id="search"
        [value]="input"
        (search)="onSearchClick($event)"
        (inputChange)="onSearchChange()"
      >
      </app-search-bar>
      
    </div>
    <div class="h-18 mr-4 items-center" *ngIf="advancedSearch">
      <app-date-picker
        *ngIf="advancedSearch"
        (selectedDate)="onDateSelected($event)"
        [label]="'main-page.sidebar.advanced-search.date' | translate"
        ></app-date-picker>
      <div class="flex gap-2 w-full items-center" *ngIf="advancedSearch">
        <app-search-bar 
          id="city-search"
          [value]="locationFreeText"
          [placeholder]="'main-page.sidebar.advanced-search.localization-search' | translate"
          (search)="onLocationInputChange($event)"
        >
        </app-search-bar>
        <div class="flex gap-2 pb-2 items-center">
          <app-button
          (click)="onAreaClick()"
          [leftIcon]="faDrawPolygon"
          [label]="'main-page.sidebar.advanced-search.aoi' | translate"
        ></app-button>
        <app-button *ngIf="!isDrawing && hasAoiDrawing"
          size="lg"
          [noLabel]="true"
          [isDangerous]="true"
          [leftIcon]="faEraser"
          (click)="clearMap()"
          ></app-button>
        </div>
        
      </div>
    </div>
    <span 
      class="text-xs pr-4 pb-4 cursor-pointer hover:underline" 
      (click)="toggleAdvancedSearch()"
      >{{advancedSearch ? ('main-page.sidebar.advanced-search.back' | translate) : ('main-page.sidebar.advanced-search.advanced-search' | translate)}}</span>
    <div [ngClass]="advancedSearch ? 'overflow-auto content-advanced pr-4': 'overflow-auto content pr-4'">
      <app-checkboxes
        *ngIf="!isLoading; else loading"
        class="text-xs"
        [isLoading]="isOrdersLoading"
        [currentValue]="service.id"
        (iconClick)="onIconClick($event)"
        (collapseClick)="onCollapseClick($event.option, $event.index)"
        (bringOnTopClick)="onTopClick($event)"
        (toggleLegend)="onToggleLegendClick($event)"
        (zoomClicked)="onZoomClicked($event)"
        (styleClicked)="onStyleClicked($event)"
        (checked)="
          onChecked(
            $event.event,
            $event.layerKey,
            $event.layers,
            $event.isDataTimeSeries,
            $event.timestamps,
            $event.wmsType,
            $event.complexOrderId,
            $event.orderId
          )
        "
        (outputDownload)="onOutputDownload($event)"
        [options]="services"
      ></app-checkboxes>
      <ng-template #loading>
        <spinner variant="rounded"></spinner>
      </ng-template>
    </div>
  </app-menu-default-card>
 </div>
<app-identify-dialog
  (cancel)="onIdentifyClose()"
>
</app-identify-dialog>

<app-layer-style-dialog
  [layer]="styleLayer"
  (opacityChange)="onOpacityChange($event)"
  (styleChange)="onStyleChange($event)"
  (close)="onStyleClose()"
  [isVisible]="stylingVisible"
  *ngIf="stylingVisible"
></app-layer-style-dialog>

<menu-service-dialog
  *ngIf="openService"
  [ngClass]="isDrawingClass()"
  [open]="openService"
  (cancel)="onServiceClose()"
  (confirm)="onServiceClose()"
  (orderMade)="onOrderMade()"
  [service]="service"
  (tabChanged)="activeDialogTab = $event"
  [ordersDisabled]=" activeDialogTab === 'order' && (!hasOrderPermission || !hasOrderAccess)"
>
</menu-service-dialog>

<app-alert-window
	*ngIf="activeDialogTab === 'order' && hasOrderAccess && !hasOrderPermission"
	[title]="'alert-dialog.commercialPermissionPending.title' | translate"
	[textContent]="'alert-dialog.commercialPermissionPending.message' | translate"
	[confirmLabel]="'alert-dialog.commercialPermissionPending.action' | translate"
	(confirmBtnClick)="onDialogConfirm()"
></app-alert-window>

<app-alert-window
	*ngIf="activeDialogTab === 'order' && !hasOrderAccess"
	[title]="'alert-dialog.userOrdersNotAllowed.title' | translate"
	[textContent]="'alert-dialog.userOrdersNotAllowed.message' | translate"
	[confirmLabel]="'alert-dialog.userOrdersNotAllowed.action' | translate"
	(confirmBtnClick)="onDialogConfirm()"
></app-alert-window>
